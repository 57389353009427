import { LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { createContext, useContext, useEffect, useState } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

const SectionContext = createContext<{
  fields: Page_Flexcontent_Flex_Content | null
}>({
  fields: null
})

export default function ContentAccordion({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <SectionContext.Provider value={{ fields }}>
      <section data-component="ContentAccordion" className="section">
        <div className="md:hidden">
          <Mobile />
        </div>
        <div className="hidden md:block">
          <Desktop />
        </div>
      </section>
    </SectionContext.Provider>
  )
}

function Mobile() {
  const { fields } = useContext(SectionContext)

  return (
    <>
      <div className="container mb-9">
        <Content className="mb-5 children-headings:mb-2 children-headings:text-3xl children-headings:font-semibold children-headings:pr-12 children-p:text-hh-blue">
          {fields?.title}
        </Content>

        <Content>{fields?.description}</Content>
        {fields?.links && (
          <div className="flex items-center gap-2 mt-7 sm:mt-10">
            {fields.links.map((link, index) => (
              <Button className={clsx(index >= 1 && 'btn--alt', 'button-green')} key={index} to={link?.link?.url || '/'}>
                {link?.link?.title}
              </Button>
            ))}
          </div>
        )}
      </div>
      <Accordion />
    </>
  )
}

function Desktop() {
  const { fields } = useContext(SectionContext)

  return (
    <div className="grid grid-cols-2 gap-x-4 lg:gap-x-14 container">
      <div className="col-span-1">
        <div className="pr-7 lg:pr-20">
          <Content className="children-headings:text-5xl children-headings:font-semibold children-p:text-hh-blue children-headings:mb-5">
            {fields?.title}
          </Content>
          <Content className="mt-6">{fields?.description}</Content>
          {fields?.links && (
            <div className="flex items-center gap-2 mt-7 sm:mt-10">
              {fields.links.map((link, index) => (
                <Button className={clsx(index >= 1 && 'btn--alt', 'button-green')} key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="col-span-1">
        <Accordion />
      </div>
    </div>
  )
}

function Accordion() {
  const { fields } = useContext(SectionContext)
  const [currentIndex, setCurrentIndex] = useState(0)

  const [s] = useSearchParams()

  const openItem = s.get('acc')

  useEffect(() => {
    const selectedItemIndex = fields?.items?.findIndex((edge) => edge?.itemId === openItem) || 0

    if (selectedItemIndex !== -1) {
      setCurrentIndex(selectedItemIndex)
    }
  }, [fields?.items, openItem])

  useEffect(() => {
    document.getElementById('accordion-wrapper')?.scrollIntoView()
  }, [])

  return (
    <div id="accordion-wrapper">
      {fields?.items?.map((edge, i) => {
        const active = currentIndex === i

        return (
          <div
            id={edge?.itemId ?? undefined}
            key={edge?.title}
            className={clsx(
              active
                ? 'bg-hh-concrete'
                : 'border-y border-x-2 border-x-transparent border-hh-mine-shaft/10 hover:bg-hh-concrete/50 hover:border-x-hh-mine-shaft/10'
            )}
          >
            <button
              className="group flex items-center justify-between w-full px-4 lg:px-8 py-8"
              onClick={() => {
                if (currentIndex === i) {
                  setCurrentIndex(-1)
                } else {
                  setCurrentIndex(i)
                }
              }}
            >
              <h3
                className={clsx(
                  active ? 'text-hh-blue' : 'text-hh-mine-shaft/50',
                  'text-xl lg:text-2xl text-left font-semibold lg:group-hover:underline'
                )}
              >
                {edge?.title}
              </h3>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={clsx(!active && '-rotate-90', 'transition-transform')}
              >
                <path
                  d="M0.29669 1.81108L1.71164 0.400085L7.00821 5.68908L12.3048 0.400085L13.7197 1.81508L8.42316 7.10007C8.04785 7.47501 7.53889 7.68565 7.00821 7.68565C6.47752 7.68565 5.96856 7.47501 5.59326 7.10007L0.29669 1.81108Z"
                  fill="#323232"
                  fillOpacity="0.5"
                />
              </svg>
            </button>
            <div
              className={clsx(
                active ? 'h-auto opacity-100' : 'h-0 opacity-0 pointer-events-none',
                'px-4 lg:px-8 transition-all overflow-hidden'
              )}
            >
              <Content className={clsx('lg:pr-12', !edge?.icon && 'pb-8')}>{edge?.description}</Content>
              <div className="flex justify-end">
                <LossePlaatjie maxwidth={100} src={edge?.icon} className="-mt-8 lg:-mt-20 mr-3 pb-8 lg:pb-4" />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
