import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUspBlocks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUspBlocks" className="xl:py-5 container-lg">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-6 xl:gap-9">
        {fields.items?.map((item, index) => (
          <div className="bg-hh-blue p-4 md:pl-7 md:pr-5 md:py-6 bg-opacity-[0.15]" key={index}>
            <div className={clsx(index % 2 === 0 ? 'text-hh-blue' : 'text-hh-mine-shaft', 'text-xl sm:text-2xl uppercase font-bold')}>
              {item?.title}
            </div>
            <div className="text-sm sm:text-base text-hh-mine-shaft font-light font-hh-paragraph">{item?.subtitle}</div>
          </div>
        ))}
      </div>
    </section>
  )
}
