import { useLoaderData } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function HeaderDesktop() {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <ul
      className={clsx(
        (header.menuHeader?.length || 0) < 3 ? 'gap-6' : 'gap-10 xl:gap-20',
        'mb-0 hidden w-full list-none items-center  justify-end lg:flex'
      )}
    >
      <HeaderMenu />
    </ul>
  )
}
