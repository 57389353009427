import Content from '~/components/elements/Content'
import type { ContentNode, Page_Flexcontent_Flex_Projects } from '~/graphql/types'
import Project from './post-types/Project'
import Uitje from './post-types/Uitje'

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Projects) => {
  const items = fields.projects?.edges as Edge[]

  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Project: Project,
  Uitje: Uitje
}

export default function ProjectsSlider({ fields }: { fields: Page_Flexcontent_Flex_Projects }) {
  const postTypeName = getPostTypeName(fields)
  const Component = PostTypes[postTypeName] || PostTypes.Post

  return (
    <section data-component="ProjectsSlider" className="section">
      <div className="container">
        <div className="flex flex-col gap-1 sm:gap-6">
          <Content className="content-md max-md:px-4 children-p:text-hh-blue children-p:text-base max-md:children-headings:!text-left max-md:children-p:!text-left md:children-p:text-2xl children-p:font-hh-heading">
            {fields.description}
          </Content>
          <div className="col-span-1">
            <Component data={fields} />
          </div>
        </div>
      </div>
    </section>
  )
}
