import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerVideo from './BannerVideo'
import BannerDefaultSmall from './BannerDefaultSmall'
import BannerVacancy from './BannerVacancy'
import BannerImage from './BannerImage'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    defaultsmall: BannerDefaultSmall,
    video: BannerVideo,
    banner_vacancy: BannerVacancy,
    image: BannerImage
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
