import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useInfo from '~/hooks/useInfo'
import { replaceInfoVariables } from '~/services/utils'

export default function ContentCTAAlt({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const info = useInfo()
  return (
    <section data-component="ContentCTAAlt" className="py-5 sm:py-10 bg-hh-blue">
      <div className="container">
        <div className="max-w-screen-md mx-auto">
          <div className="grid grid-cols-1 lg:gap-0 gap-6 sm:gap-6 lg:grid-cols-3">
            <div className="lg:col-span-2">
              <Content className="text-3xl sm:text-4xl font-bold text-center text-white sm:children-h2:!mb-1 lg:text-left">
                {fields.description}
              </Content>
            </div>
            <div className="flex max-sm:mb-1 max-sm:w-full items-center justify-center lg:justify-end">
              {fields.links && (
                <div className="flex items-center max-sm:w-full justify-center gap-2">
                  {fields.links.map((link, index) => (
                    <Button
                      className={clsx(index >= 1 && 'button-mine-shaft', 'button-mine-shaft max-sm:w-full')}
                      key={index}
                      to={link?.link?.url || '/'}
                    >
                      {replaceInfoVariables(link?.link?.title || '', info)}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
