import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function BannerImage({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerImage" className="section container-lg max-lg:px-0 max-lg:pt-0">
      <div className="w-full bg-hh-mine-shaft overflow-clip px-6 sm:px-10 lg:px-[65px]  relative">
        {fields?.description && (
          <div className="grid grid-cols-1 lg:grid-cols-5 w-full justify-center">
            <div className="lg:col-span-3 pt-10 pb-20 lg:py-28">
              <Content
                style={{ textShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
                className="children-headings:mb-2 children-h2:text-lg lg:children-h2:text-3xl children-h2:font-light children-h1:text-5xl sm:children-h1:text-6xl lg:children-h1:text-7xl children-h1:font-semibold text-white"
              >
                {fields?.description}
              </Content>
              <div className="flex sm:flex-row flex-col items-center gap-3 mt-8 sm:mt-10">
                {fields.links?.map((link, index) => (
                  <LosseLink
                    className={clsx(index === 0 ? 'button-green' : 'button-white-opaque', 'max-sm:w-full')}
                    to={link?.link?.url}
                    key={index}
                  >
                    {link?.link?.title}
                  </LosseLink>
                ))}
              </div>
            </div>
            <div className="lg:col-span-2 flex justify-center items-end">
              <div className="relative w-[400px] lg:w-[470px]">
                <div className="absolute w-full lg:w-[600px] h-auto bottom-[220px] sm:bottom-[250px] lg:bottom-[250px] left-0 z-10">
                  <div className="absolute w-full h-full left-0 bottom-0 [background:linear-gradient(180deg,rgba(50,50,50,0.00)_0%,#323232_79%)]" />
                  <svg
                    className="w-full h-auto"
                    width="761"
                    height="377"
                    viewBox="0 0 761 377"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M209.304 60.0292H159.475V0.934814H0V376.829H159.475V317.735H209.304V376.829H368.054V0.934814H209.304V60.0292ZM32.2488 205.603L124.301 110.923L208.383 199.556H350.963V283.213H66.5697V205.598H32.2488V205.603ZM131.797 109.493H350.968V193.15H211.139L131.797 109.493ZM226.39 18.0209H350.968V103.086H122.974L17.0859 212.01H60.1632V289.625H350.963V359.743H226.384V300.649H142.384V359.743H17.0859V18.0209H142.389V77.1153H226.39V18.0209Z"
                      fill="white"
                    />
                    <path
                      d="M761.026 376.829V0.934814H602.275V60.0292H552.447V0.934814H392.972V376.829H552.447V317.735H602.275V376.829H761.026ZM597.771 193.155H410.063V109.498H429.709L448.034 83.8716H491.708L510.223 109.498H545.547L565.471 146.534H584.135L605.257 182.481L597.761 193.16L597.771 193.155ZM622.436 169.297H729.049L621.217 283.306H410.058V199.562H601.077L622.436 169.297ZM535.366 300.644V359.738H410.063V289.707H623.978L743.945 162.973L619.135 162.885L609.309 176.789L587.74 140.117H569.307L549.382 103.081H513.504L495.009 77.4547H444.713L426.388 103.081H410.073V18.0209H535.376V77.1153H619.377V18.0209H743.955V359.743H619.377V300.649H535.376L535.366 300.644Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="absolute bottom-0 left-0 w-full h-[320px] z-30 [background:linear-gradient(180deg,rgba(50,50,50,0.00)_0%,#323232_79%)]" />
                <LossePlaatjie
                  maxwidth={2000}
                  loading="eager"
                  placeholder="blur"
                  className="h-auto z-20 relative lg:absolute bottom-0 left-0 object-contain w-full"
                  src={fields.image}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
