import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentVideo({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentVideo" className="bg-hh-mine-shaft" id={fields?.anchorId || ''}>
      <div className="container section xl:py-32">
        <div className="grid md:grid-cols-2 gap-12 lg:gap-24">
          <div className="col-span-1 flex items-center">
            <Content className="content-lg w-full children:!text-white children-h2:mb-4">{fields?.description}</Content>
          </div>
          <div className="col-span-1 flex items-center">
            <div className="w-full">
              <div style={{ padding: '57.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src={fields?.video || ''}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Werken bij Heinen Hopman"
                ></iframe>
              </div>

              <script src="https://player.vimeo.com/api/player.js"></script>

              <div className="flex items-start justify-between xl:px-24">
                {fields?.stats?.map((edge, index) => (
                  <div key={index} className="text-center mt-14 text-white">
                    <h3 className="title text-3xl md:text-5xl xl:text-6xl">{edge?.label}</h3>
                    <p className="font-hh-form font-light text-lg mt-3">{edge?.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
