import { LosseBlogBink, useLosseBlogBink, useSearchParams } from '@ubo/losse-sjedel'
import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Content from '~/components/elements/Content'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies, Vacature } from '~/graphql/types'
import clsx from 'clsx'

export default function VacanciesOverview({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  if (!fields.vacancies?.pageInfo) return null

  return (
    <section id="VacanciesOverview" className="mb-12">
      {/* @ts-ignore */}
      <LosseBlogBink pageInfo={fields.vacancies?.pageInfo}>
        <View fields={fields} />
      </LosseBlogBink>
    </section>
  )
}

function View({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  const vacancies = fields.vacancies?.edges as { node: Vacature }[]
  const [stickyFilters, setStickyFilters] = useState(false)
  const filtersRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (!filtersRef.current) return

      const top = filtersRef.current.offsetTop
      const scrollY = window.scrollY

      setStickyFilters(scrollY >= top)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  let filteredVacancies = vacancies

  if (fields.vacancytype) {
    filteredVacancies = vacancies.filter((edge) => {
      const vacancy = edge.node as Vacature

      const mainFilter = vacancy.categories?.edges.filter((edge) => edge.node.parentDatabaseId === 12)

      return mainFilter![0].node.databaseId === fields.vacancytype?.databaseId
    })
  }

  return (
    <>
      <div
        className={clsx(
          stickyFilters && Number(fields.filters?.length) > 9 && 'fixed w-full top-24 z-[99] bg-white shadow-md',
          'hidden lg:block transition-all'
        )}
      >
        <div className={clsx(stickyFilters ? 'py-4' : 'py-10', 'relative container transition-all')}>
          <div className="max-w-screen-xl mx-auto flex flex-col gap-5">
            <div ref={filtersRef} className="flex md:flex-row md:items-end flex-col justify-between gap-6 md:gap-4 lg:gap-8">
              <Filters fields={fields} isSticky={stickyFilters} />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="container flex flex-col gap-4 md:gap-8 lg:gap-9 py-6 lg:py-9">
          <div className="max-w-screen-xl mx-auto w-full">
            <Content
              className={clsx(
                fields.vacancytype?.databaseId === 13
                  ? 'children-p:!text-hh-blue'
                  : 'sm:children-p:text-2xl md:children-p:text-3xl children-p:font-bold',
                'content children-p:text-xl children-p:font-hh-heading children-p:text-hh-mine-shaft'
              )}
            >
              {fields.description?.replace(
                '{count}',
                `<span class="text-hh-blue font-hh-heading">${filteredVacancies.length} VACATURE${
                  filteredVacancies.length > 1 ? 'S' : ''
                }</span>`
              )}
            </Content>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 lg:gap-7 xl:gap-14">
            {filteredVacancies.map((item, index) => {
              const vacancy = item.node as Vacature

              return <Preview key={index} fields={vacancy} />
            })}
          </div>

          {/* {(hasNextPage || hasPrevPage) && (
            <div className="flex justify-center py-6 lg:py-10">
              <Pagination />
            </div>
          )} */}
        </div>
      </div>
      <div className="lg:hidden">
        <MobileFilter fields={fields} />
      </div>
    </>
  )
}

function MobileFilter({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)

  return (
    <div className={clsx(mobileFilterOpen && 'bg-white', 'fixed w-full bottom-0 z-[99] pt-4 pb-2 px-2 transition-colors')}>
      <button
        type="button"
        onClick={() => setMobileFilterOpen(!mobileFilterOpen)}
        className="bg-hh-blue text-white w-full py-4 font-semibold font-hh-heading text-lg rounded-full"
      >
        {!mobileFilterOpen ? <span>FILTERS</span> : <span>TOON {fields.vacancies?.edges.length} RESULTATEN</span>}
      </button>
      <AnimatePresence>
        {mobileFilterOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'fit-content' }}
            exit={{ height: 0 }}
            className="bg-white text-hh-mine-shaft"
          >
            <div className="mx-12 pt-10 pb-6">
              <Filters fields={fields} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function Filters({ fields, isSticky = false }: { fields: Page_Flexcontent_Flex_Vacancies; isSticky?: boolean }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const { clear } = useLosseBlogBink()

  function handleClear() {
    const filterSelects = document.querySelectorAll('.blog-bink-select') as NodeListOf<HTMLSelectElement>

    filterSelects.forEach((entry) => {
      return (entry.value = '')
    })

    clear()
  }

  return (
    <>
      {fields.filters && fields.filters.length > 0 && (
        <>
          {fields.filters
            ?.filter((e) => e?.parentDatabaseId === null)
            ?.sort((a, b) => (a?.databaseId || 0) - (b?.databaseId || 0))
            .map((filter, index) => {
              const activeFilters = searchParams.get('_f')?.split(',') || []
              const otherFilterIds = fields.filters?.filter((e) => e?.parentDatabaseId !== filter?.databaseId).map((e) => e?.databaseId)

              const defaultValue = activeFilters.filter((af: string) => {
                if (!otherFilterIds?.includes(parseInt(af))) return true
                return false
              })[0]

              return (
                <div key={index} className="relative max-lg:mb-4">
                  <div
                    className={clsx(
                      isSticky ? 'opacity-0 h-0' : 'opacity-100 h-auto',

                      'flex items-center justify-between transition-all'
                    )}
                  >
                    <div className="text-base sm:text-xl md:mb-4 md:text-2xl mb-2 font-hh-heading uppercase font-bold">{filter?.name}</div>
                  </div>
                  <div className="relative">
                    <svg
                      className="absolute top-0 bottom-0 right-4 lg:right-7 my-auto"
                      width="10"
                      height="5"
                      viewBox="0 0 10 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.41411 4.1972L9.47411 1.1372C9.59827 1.01229 9.66797 0.843323 9.66797 0.667199C9.66797 0.491075 9.59827 0.322107 9.47411 0.197199C9.41213 0.134713 9.3384 0.0851172 9.25716 0.0512714C9.17592 0.0174256 9.08878 -2.53171e-08 9.00077 -2.9164e-08C8.91277 -3.3011e-08 8.82563 0.0174256 8.74439 0.0512714C8.66315 0.0851172 8.58942 0.134713 8.52744 0.197199L5.47411 3.26387C5.41213 3.32635 5.3384 3.37595 5.25716 3.40979C5.17592 3.44364 5.08878 3.46106 5.00077 3.46106C4.91276 3.46106 4.82563 3.44364 4.74439 3.40979C4.66315 3.37595 4.58941 3.32635 4.52744 3.26387L1.4741 0.197199C1.34945 0.0716627 1.18004 0.000786239 1.00313 0.00016111C0.826221 -0.000464019 0.656308 0.069214 0.530772 0.193866C0.405236 0.318517 0.334359 0.487932 0.333734 0.664842C0.333109 0.841751 0.402787 1.01166 0.527439 1.1372L3.58744 4.1972C3.96244 4.57173 4.47077 4.78211 5.00077 4.78211C5.53077 4.78211 6.0391 4.57173 6.41411 4.1972Z"
                        fill="black"
                      />
                    </svg>
                    <select
                      id={`filter-${filter?.databaseId}`}
                      defaultValue={defaultValue}
                      className="blog-bink-select bg-transparent md:w-[unset] w-full font-hh-heading text-base lg:text-lg md:text-xl invalid:required:text-hh-mine-shaft/50 placeholder:text-hh-mine-shaft/50 relative border !appearance-none border-black rounded-full h-[50px] lg:h-[60px] text-hh-mine-shaft/50 pl-7 pr-8 min-w-[200px] lg:min-w-[280px]"
                      onClick={(e) => {
                        const element = document.getElementById(`filter-${filter?.databaseId}`)
                        // @ts-ignore
                        if (element && e.target.value !== '') {
                          element.style.color = '#232323'
                        }
                      }}
                      onChange={(e) => {
                        const otherActiveFilters = activeFilters.filter((af: string) => {
                          if (otherFilterIds?.includes(parseInt(af))) return true
                          return false
                        })

                        searchParams.set(
                          '_f',
                          `${otherActiveFilters.length > 0 ? `${e.target.value},${otherActiveFilters.join(',')}` : e.target.value}`
                        )
                        setSearchParams(searchParams, {
                          state: {
                            scroll: false
                          }
                        })
                      }}
                    >
                      <option disabled selected value="" className="relative">
                        {`Selecteer een ${filter?.name?.toLowerCase()}`}
                      </option>
                      {fields.filters
                        ?.filter((e) => e?.parentDatabaseId === filter?.databaseId)
                        .map((f, subIndex) => (
                          <option className="text-black text-base relative" key={subIndex} value={f?.databaseId}>
                            {f?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )
            })}
          <div className="mx-auto w-fit">
            <button
              disabled={!searchParams.has('_f')}
              className="button-blue mt-3 disabled:opacity-10"
              type="button"
              onClick={() => handleClear()}
            >
              Wis filters
            </button>
          </div>
        </>
      )}
    </>
  )
}
