import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentDefault" className="section">
      <div className="container">
        {fields?.twoColumns ? (
          <div className="grid lg:grid-cols-2 gap-4 lg:gap-16">
            <div className="col-span-1">
              {fields.title && (
                <Content className="content text-hh-mine-shaft children-p:text-hh-blue children-p:font-hh-heading children-p:text-xl children-headings:mb-4 mb-6">
                  {fields?.title}
                </Content>
              )}
            </div>
            <div className="col-span-1">
              {fields.description && <Content className="content text-hh-mine-shaft">{fields?.description}</Content>}
              {fields.links && (
                <div className="flex items-center gap-2 mt-4">
                  {fields.links.map((link, index) => (
                    <Button
                      target={link?.link?.target || '_self'}
                      className={clsx(index >= 1 && 'btn--alt', 'btn', 'max-sm:w-full')}
                      key={index}
                      to={link?.link?.url || '/'}
                    >
                      {link?.link?.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {fields.title && (
              <Content className="block content text-hh-mine-shaft children-p:text-hh-blue children-p:font-hh-heading children-p:text-xl children-headings:mb-4 mb-6 lg:w-3/5">
                {fields?.title}
              </Content>
            )}
            {fields.description && <Content className="content text-hh-mine-shaft lg:w-3/5">{fields?.description}</Content>}
            {fields.links && (
              <div className="flex items-center gap-2 mt-7 sm:mt-10">
                {fields.links.map((link, index) => (
                  <Button
                    target={link?.link?.target || '_self'}
                    className={clsx(index >= 1 && 'btn--alt', 'button-mine-shaft-opaque')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </section>
  )
}
