import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCTABlock({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentCTABlock" className="section">
      <div className="container">
        <div className="bg-hh-blue flex items-center gap-8 sm:gap-12 md:gap-[70px] p-7 sm:p-10 md:p-[75px] justify-between">
          <div className="max-xl:hidden">
            <LossePlaatjie className="w-[100px] xl:w-[145px] min-w-[100px] xl:min-w-[145px]" src={fields.image} />
          </div>
          <div className="flex xl:flex-row flex-col items-center justify-between gap-10 sm:gap-20">
            <Content className="children-headings:text-3xl sm:children-headings:text-5xl children-headings:font-semibold children-p:text-hh-mine-shaft children-headings:text-white children-headings:mb-5">
              {fields?.title}
            </Content>
            {fields?.links && (
              <div className="flex items-center gap-2">
                {fields.links.map((link, index) => (
                  <Button
                    className={clsx(index >= 1 && 'btn--alt', 'button-mine-shaft-opaque', 'whitespace-nowrap')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
