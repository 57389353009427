import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import { useLocation } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormPopup({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const location = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (location.hash === `#${fields.trigger}`) {
      setOpen(true)
    }
  }, [location, fields.trigger])

  function closePopup() {
    window.location.hash = ''
    setOpen(false)
  }

  if (!open) return null

  return (
    <section
      data-component="FormPopup"
      className="bg-hh-mine-shaft/80 cursor-pointer z-[100] fixed w-full left-0 top-0 h-full flex justify-center items-center"
      onClick={() => {
        closePopup()
      }}
    >
      <div className="container">
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className="bg-hh-concrete relative max-w-screen-md mx-auto cursor-auto p-7 sm:p-20"
        >
          <svg
            className="absolute right-5 sm:right-8 top-5 sm:top-8 cursor-pointer"
            onClick={() => {
              closePopup()
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.1211 12.0002L23.5608 2.56161C24.1467 1.97565 24.1467 1.02563 23.5608 0.439714C22.9748 -0.146246 22.0248 -0.146246 21.4389 0.439714L12.0002 9.8793L2.56161 0.439714C1.97565 -0.146246 1.02563 -0.146246 0.439714 0.439714C-0.146199 1.02567 -0.146246 1.9757 0.439714 2.56161L9.8793 12.0002L0.439714 21.4389C-0.146246 22.0248 -0.146246 22.9748 0.439714 23.5608C1.02567 24.1467 1.9757 24.1467 2.56161 23.5608L12.0002 14.1211L21.4388 23.5608C22.0248 24.1467 22.9748 24.1467 23.5607 23.5608C24.1467 22.9748 24.1467 22.0248 23.5607 21.4389L14.1211 12.0002Z"
              fill="#323232"
            />
          </svg>
          <Content className="children-headings:text-3xl sm:children-headings:text-5xl children-headings:font-semibold children-p:text-hh-mine-shaft children-headings:text-hh-mine-shaft children-headings:mb-5">
            {fields.description}
          </Content>
          <Form
            data={fields?.form}
            scrollToConfirm={false}
            renderLoading={() => (
              <div className="relative">
                <Loading className="text-hh-blue" />
              </div>
            )}
            renderStatus={({ status, content }) => {
              return (
                <>
                  <div className="flex items-center justify-center text-white py-6">
                    {status === 'done' ? (
                      (window.location.href = '/bedankt-salarischeck')
                    ) : (
                      <div className="flex flex-col items-center justify-center gap-4 text-center">
                        <div className="bg-red-500 flex--center w-20 h-20 rounded-full box--shadow">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-20 h-20 text-white relative"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18 6L6 18"></path>
                            <path d="M6 6L18 18"></path>
                          </svg>
                        </div>

                        <Content className="content children:!text-black mt-4 text-center text-xl lg:text-2xl font-medium">{`<p>Er is iets misgegaan. Controleer je bestandsgrootte of neem contact op met de recruiter.</p>`}</Content>
                      </div>
                    )}
                  </div>
                </>
              )
            }}
            privacyUrl="/privacy-verklaring/"
            privacyUrlPlacement="privacybeleid"
            generate={false}
            onSubmitDone={() => {
              // navigate to thank you page
              window.location.href = '/bedankt-salarischeck'
            }}
            className="salary-check-form mt-6 sm:mt-10"
          >
            <div className="grid grid-cols-2">
              <div className="col-span-2">
                <FormDuufGroup showIds={[13]} />
              </div>

              <div className="pr-2">
                <FormDuufGroup showIds={[2]} />
              </div>
              <div className="pl-2">
                <FormDuufGroup showIds={[11]} />
              </div>

              <div className="col-span-2">
                <FormDuufGroup showIds={[12]} />
              </div>

              <div className="pr-2">
                <FormDuufGroup showIds={[14]} />
              </div>
              <div className="pl-2">
                <FormDuufGroup showIds={[15]} />
              </div>

              <div className="pr-2">
                <FormDuufGroup showIds={[16]} />
              </div>
              <div className="pl-2">
                <FormDuufGroup showIds={[17]} />
              </div>

              <div className="col-span-2">
                <FormDuufGroup showIds={[18]} />
              </div>

              <div className="col-span-2">
                <FormDuufSubmit />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  )
}
