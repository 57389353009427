import { type PropsWithChildren } from 'react'
import { LosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import { useUtm } from '~/services/utm'

export default function Layout({ children }: PropsWithChildren<{}>) {
  useUtm()

  return (
    <LosseLayout>
      <Header />
      <main className="min-h-screen pt-[70px] lg:pt-[100px]">{children}</main>
      <Footer />
    </LosseLayout>
  )
}
