import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Fragment } from 'react'
import { Navigation, Pagination } from 'swiper/modules'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Uitje as Props } from '~/graphql/types'

export default function Uitje({ data }: { data: Props }) {
  return (
    <Slider
      modules={[Pagination, Navigation]}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }}
      pagination={{
        clickable: true,
        bulletClass: 'swiper-pagination-bullet-dark',
        el: '.project-slider-pagination'
      }}
    >
      {data?.projects?.edges?.map((item, index) => {
        const uitje = item?.node as Uitje

        return (
          <Slide key={index} className="py-5 cursor-grab sm:py-10 !mb-4">
            <div className="grid grid-cols-5 lg:grid-cols-10">
              <div className="col-span-5 lg:col-span-6">
                <LossePlaatjie
                  maxwidth={1000}
                  className="h-[225px] md:h-[400px] xl:h-[512px] object-cover"
                  src={uitje.featuredImage?.node}
                  alt="featured"
                />
              </div>
              <div className="col-span-5 lg:col-span-4 flex justify-center items-center">
                <div className="bg-hh-mine-shaft bg-opacity-80 mix-blend-multiply xl:pb-20 px-6 md:px-14 pb-6 md:pb-10 py-6 lg:p-8 xl:p-10 w-[90%] lg:w-full text-center">
                  <h3 className="font-hh-heading font-bold mb-3 md:mb-8 text-white text-2xl md:text-4xl xl:text-5xl uppercase">
                    {uitje.title}
                  </h3>
                  <div className="grid grid-cols-2 gap-x-8 lg:gap-x-0 gap-y-3 xl:gap-y-5 text-left">
                    <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Locatie</div>
                    <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">{uitje.recap?.location}</div>
                    <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Datum</div>
                    <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">{uitje.recap?.date}</div>
                    {uitje?.recap?.items.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">{item?.title}</div>
                          <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">{item?.description}</div>
                        </Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        )
      })}
      <div className="flex justify-center">
        <div className="project-slider-pagination"></div>
      </div>
    </Slider>
  )
}
