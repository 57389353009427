import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentThanks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentThanks" className="section flex items-center min-h-[300px] sm:min-h-[700px]">
      <div className="container">
        <div className="flex justify-center mb-10">
          <svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M71.1265 5.12809C79.7128 5.12809 88.215 6.81929 96.1476 10.1051C104.08 13.3909 111.288 18.207 117.359 24.2784C123.431 30.3498 128.247 37.5576 131.533 45.4902C134.819 53.4229 136.51 61.9251 136.51 70.5113C136.51 87.852 129.621 104.482 117.359 116.744C105.098 129.006 88.4672 135.895 71.1265 135.895C53.7858 135.895 37.1554 129.006 24.8936 116.744C12.6319 104.482 5.74333 87.852 5.74333 70.5113C5.74333 53.1706 12.6319 36.5401 24.8936 24.2784C37.1554 12.0167 53.7858 5.12809 71.1265 5.12809ZM71.1265 0C52.4351 0.0305111 34.518 7.46916 21.3012 20.686C8.08439 33.9028 0.645745 51.8199 0.615234 70.5113C0.642364 89.2037 8.07992 107.123 21.2975 120.34C34.515 133.558 52.4341 140.995 71.1265 141.023C89.8179 140.992 107.735 133.553 120.952 120.337C134.169 107.12 141.607 89.2027 141.638 70.5113C141.604 51.821 134.164 33.9058 120.948 20.6897C107.732 7.47362 89.8169 0.0338901 71.1265 0Z"
              fill="#00A1FF"
            />
            <path
              d="M71.1263 11.9614C86.6649 11.9614 101.567 18.1341 112.555 29.1215C123.542 40.109 129.715 55.0112 129.715 70.5498C129.715 86.0885 123.542 100.991 112.555 111.978C101.567 122.966 86.6649 129.138 71.1263 129.138C63.4329 129.138 55.8149 127.623 48.7074 124.678C41.5998 121.734 35.1419 117.418 29.7025 111.977C24.2631 106.537 19.9487 100.078 17.0057 92.9697C14.0628 85.8615 12.5489 78.2432 12.5506 70.5498C12.5489 62.8565 14.0628 55.2382 17.0057 48.13C19.9487 41.0218 24.2631 34.5629 29.7025 29.1223C35.1419 23.6817 41.5998 19.3659 48.7074 16.4214C55.8149 13.4769 63.4329 11.9614 71.1263 11.9614ZM71.1263 10.5896C55.229 10.6066 39.9878 16.9292 28.7467 28.1703C17.5057 39.4113 11.183 54.6526 11.166 70.5498C11.183 86.4471 17.5057 101.688 28.7467 112.929C39.9878 124.17 55.229 130.493 71.1263 130.51C87.0235 130.493 102.265 124.17 113.506 112.929C124.747 101.688 131.07 86.4471 131.087 70.5498C131.07 54.6526 124.747 39.4113 113.506 28.1703C102.265 16.9292 87.0235 10.6066 71.1263 10.5896Z"
              fill="#00A1FF"
            />
            <path
              d="M62.4352 80.8687L90.3321 52.9719C90.9904 52.3135 91.7585 51.9844 92.6362 51.9844C93.514 51.9844 94.2821 52.3135 94.9404 52.9719C95.5987 53.6302 95.9279 54.4125 95.9279 55.3188C95.9279 56.2251 95.5987 57.0064 94.9404 57.6625L64.7394 87.9458C64.081 88.6042 63.313 88.9333 62.4352 88.9333C61.5574 88.9333 60.7894 88.6042 60.131 87.9458L45.9769 73.7917C45.3185 73.1333 45.0025 72.3521 45.0289 71.448C45.0552 70.5439 45.3986 69.7616 46.0592 69.101C46.7197 68.4405 47.502 68.1113 48.4061 68.1135C49.3102 68.1157 50.0914 68.4449 50.7498 69.101L62.4352 80.8687Z"
              className="fill-hh-green"
            />
          </svg>
        </div>
        <Content className="children-headings:text-3xl md:children-headings:text-5xl children-headings:font-semibold children-p:text-hh-mine-shaft/80 children-p:text-2xl children-headings:mb-5">
          {fields?.title}
        </Content>
        {fields?.links && (
          <div className="flex max-sm:flex-wrap justify-center items-center gap-2 mt-10 sm:mt-14">
            {fields.links.map((link, index) => (
              <Button
                className={clsx(index >= 1 && 'button-mine-shaft-opaque', 'button-green', 'max-sm:w-full')}
                key={index}
                to={link?.link?.url || '/'}
              >
                {link?.link?.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
