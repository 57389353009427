import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormDefault({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="formulier" data-component="FormDefault" className="section overflow-clip">
      <div className="container">
        <div className="grid grid-cols-6 lg:grid-cols-12 gap-6 lg:gap-20 lg:mt-16 lg:mb-20" id="solliciteren">
          <div className="col-span-6">
            <Content className="children-headings:text-3xl md:children-headings:text-5xl children-strong:font-normal children-strong:text-hh-blue children-headings:mb-3 children-headings:font-semibold">
              {fields?.description}
            </Content>
          </div>
          <div className="col-span-6">
            <div className="relative lg:p-0 pr-4">
              <Form
                className="relative lg:pb-16"
                data={fields?.form}
                scrollToConfirm={false}
                renderLoading={() => (
                  <div className="relative">
                    <Loading className="text-hh-blue" />
                  </div>
                )}
                renderStatus={({ status, content }) => {
                  return (
                    <>
                      <div className="flex items-center justify-center text-white py-6">
                        {status === 'done' ? (
                          (window.location.href = `${fields.thankspage ? fields.thankspage.url : '/bedankt'}`)
                        ) : (
                          <div className="flex flex-col items-center justify-center gap-4 text-center">
                            <div className="bg-red-500 flex--center w-20 h-20 rounded-full box--shadow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="w-20 h-20 text-white relative"
                                viewBox="0 0 24 24"
                              >
                                <path d="M18 6L6 18"></path>
                                <path d="M6 6L18 18"></path>
                              </svg>
                            </div>

                            <Content className="content children:!text-black mt-4 text-center text-xl lg:text-2xl font-medium">{`<p>Er is iets misgegaan. Controleer je bestandsgrootte of neem contact op met de recruiter.</p>`}</Content>
                          </div>
                        )}
                      </div>
                    </>
                  )
                }}
                privacyUrl="/privacy-verklaring/"
                privacyUrlPlacement="privacybeleid"
                generate={true}
                onSubmitDone={() => {
                  // navigate to thank you page
                  window.location.href = '/bedankt'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
