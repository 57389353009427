import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentVideoAlt({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentVideoAlt" className="section">
      <div className="container">
        <Content className="mb-10 md:mb-[72px] children-headings:text-3xl md:children-headings:text-5xl children-headings:font-semibold children-p:text-hh-blue children-headings:text-hh-mine-shaft children-headings:mb-5">
          {fields?.title}
        </Content>

        <div style={{ padding: '57.25% 0 0 0', position: 'relative' }}>
          <iframe
            src={fields?.video || ''}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Werken bij Heinen Hopman"
          ></iframe>
        </div>

        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </section>
  )
}
