import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentCTABlockAlt({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentCTABlockAlt" className="pb-10 sm:pb-12 lg:pb-16 xl:pb-20">
      <div className="container">
        <div className="bg-hh-mine-shaft flex items-center gap-8 sm:gap-12 md:gap-[70px] justify-between">
          <div className="pt-4 flex items-center max-xl:hidden">
            <LossePlaatjie className="min-w-[250px] w-[250px]" src={fields.image} />
          </div>
          <div className="flex xl:flex-row flex-col xl:pl-0 p-7 sm:p-10 md:p-[75px] items-center justify-between gap-10 sm:gap-20">
            <Content className="children-headings:text-3xl md:children-headings:text-5xl children-headings:font-semibold children-p:text-white children-headings:text-white children-headings:mb-5">
              {fields?.title}
            </Content>
            {fields?.links && (
              <div className="flex items-center gap-2">
                {fields.links.map((link, index) => (
                  <Button
                    className={clsx(index >= 1 && 'btn--alt', 'button-white-opaque', 'whitespace-nowrap')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
