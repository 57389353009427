import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormContact from './FormContact'
import FormOpenAvond from './FormOpenAvond'
import FormDefault from './FormDefault'
import FormPopup from './FormPopup'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: FormContact,
    openavond: FormOpenAvond,
    default: FormDefault,
    popup: FormPopup
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
